import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/en";
import { compose } from "recompose";
import "./index.css";
import { Container, Row, Col } from "reactstrap";
import "react-animated-slider/build/horizontal.css";
import s2 from "../static/images/plan-s2.png";
import s3 from "../static/images/plan-s3.png";
import s4 from "../static/images/plan-s4.png";
import s5 from "../static/images/plan-s5.jpg";
import s6 from "../static/images/plan-s6.png";
import s7 from "../static/images/plan-s7.jpg";
import SEO from "../components/seo.js";
import SmoothScrollLink from "../components/smoothScrollLink";

const IndexPage = compose()((props) => (
  <Layout location={props.location}>
    <div>
      <Container>
        <Row>
          <Col md={12} className="features-beg pt-4 pb-0">
            <h1>Architecture</h1>
            <p className="text-center">
              The original Urbanistic-architectural concept of the area and the
              complex design solution was provided by the architectural studio
              Atrium, s.r.o. from Košice, the holder of many major awards for
              design and architecture and one of the largest processors of
              zoning plans in Slovakia.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="architecture-nav sticky-top mt-4 mb-4">
        <ul>
          <li>
            <SmoothScrollLink to="S2">S2</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S3">S3</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S4">S4</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S5">S5</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S6+S7">S6</SmoothScrollLink>
            </li>
          <li>
            <SmoothScrollLink to="S6+S7">S7</SmoothScrollLink>
          </li>
        </ul>
      </Container>
      <Container className="col-utilization" id="S2">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Sector S2</h2>
                <p className="mb-2">
                  <strong>Description:</strong> Logistics and trading complex{" "}
                </p>
                <p className="mb-2">
                  <strong>Entrance:</strong>  from the south-west by tertiary road
                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> S2A: sold | S2B: built
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infrastructure:
                </h4>
                <ul>
                  <li>roads form a circuit</li>
                  <li>roads are dimensioned for trucks</li>
                  <li>pavement leads from the building complex to the future bus stop</li>
                  <li>parking lot – next to the main buildings</li>
                  <li>access to the goods through loading docks</li>
                </ul>

                <h4 className="text-left strong mb-3">Surroundings:</h4>
                <p>
                  <strong>in the north: </strong>wildlife corridor Balka stream 
                  (is to be preserved and developed)
                  <br />
                  <strong>in the east: </strong>Nová Polhora municipality <br />
                  <strong>in the south: </strong>roundabout and road 3325
                  <br />
                  <strong>in the west: </strong>football field
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s2} mb={3} />
                <h4 className="text-left strong mb-3">
                  Building complex S2 (64 188 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Description:</strong> building complex consists of 2 logistics buildings 
                </p>
                <p>
                  <strong>Construction of the buildings:</strong> multi-nave and multi-modal
                </p>
                <p>
                  <strong>Material used:</strong> steel and reinforced concrete 
                </p>
              </Col>
              <Col md={12} className="pt-4">
                <p>
                September 2019 – entire sector S2 was sold to P3 Žilina s.r.o.<br />
                January 2020 - the first logistics hall with the size of 12 800 m<sup>2</sup> passed the final inspection.<br />
                April/May 2020 – the construction of the second logistics hall with the size of 12 800 m<sup>2</sup> begins.<br />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S3">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Sector S3</h2>
                <p className="mb-2">
                  <strong>Description:</strong> Commercial service complex, containing warehouses, commercial, service and administrative offices and the required transport and green areas
{" "}
                </p>
                <p className="mb-2">
                  <strong>Entrance:</strong>  from collector road
                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> Development opportunity
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infrastructure:
                </h4>
                <ul>
                  <li>roads are to form a circuit</li>
                  <li>roads are dimensioned for trucks</li>
                  <li>pavement is to lead from the service-administrative centre to the future bus stop</li>
                  <li>parking lot – on the north of the access road</li>
                  <li>access to the goods through loading docks</li>
                </ul>

                <h4 className="text-left strong mb-3">Surroundings:</h4>
                <p>
                majority of green areas is designed within the area of Starina water line.
                  <br />
                  the Starina water line is undergoing the western area of the sector <br />
                  sector is between the road 3325 and collector road

                  <br />
                  <strong>in the north:</strong> motorway interchange with Nová Polhora motorway feeder <br />
                  <strong>in the south: </strong> land drainage system
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s3} mb={3} />
                <h4 className="text-left strong mb-3">
                  Building complex S3 (64 032 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Description:</strong> main building is to form a single unit 
                </p>
                <p>
                  <strong>Construction of the buildings:</strong>  multi-nave and multi-modal
                </p>
                <p>
                  <strong>Material used:</strong>  steel and reinforced concrete
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S4">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Sector S4</h2>
                <p className="mb-2">
                  <strong>Description:</strong> Logistics complex containing warehouses, administrative offices and the required transport and green areas
{" "}
                </p>
                <p className="mb-2">
                  <strong>Entrance:</strong>  2 main entrances from the collector road

                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> Reserved
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infrastructure:
                </h4>
                <ul>
                  <li>roads are to form a circuit</li>
                  <li>roads are dimensioned for trucks</li>
                  <li>pavement is to lead from administration centre and from docks to the future bus stop </li>
                  <li>parking lot for trucks – between the entrance for passenger cars, along the inner service circuit</li>
                  <li>access to the goods from the eastern and western facades of the warehouses through loading docks
</li>
                </ul>

                <h4 className="text-left strong mb-3">Surroundings:</h4>
                <p>
                majority of green areas is designed in front of the alley facade<br/>
                  <strong>in the north: </strong>road 3325 and the roundabout 

                  <br />
                  <strong>in the east: </strong>Nová Polhora municipality (separated by insulating green area)
                  <br />
                  <strong>in the west: </strong>collector road
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s4} mb={3} />
                <h4 className="text-left strong mb-3">
                  Building complex S4 (118 031 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Description:</strong>  main building is to form a single unit 
                </p>
                <p>
                  <strong>Construction of the buildings:</strong> multi-nave and multi-modal
                </p>
                <p>
                  <strong>Material used:</strong> steel and reinforced concrete 
                </p>
              </Col>
              <Col md={12} className="pt-4">
                <p>
                <strong>The area was sold to the company LABAŠ s.r.o. for the construction of warehouses.</strong>

                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S5">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Sector S5</h2>
                <p className="mb-2">
                  <strong>Description:</strong>  Logistics complex containing warehouse, administrative offices and the required transport and green areas
 {" "}
                </p>
                <p className="mb-2">
                  <strong>Entrance:</strong> main entrances from the service road
                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> Reserved
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infrastructure:
                </h4>
                <ul>
                  <li>roads form a circuit</li>
                  <li>roads are dimensioned for trucks</li>
                  <li>pavement is to lead from the building complex to the future bus stop </li>
                  <li>parking lot for passenger cars – at the entrance and exit of the sector 
</li>
                  <li>access to the goods from the eastern and western facades of the warehouses through loading docks
</li>
                </ul>

                <h4 className="text-left strong mb-3">Surroundings:</h4>
                <p>
                majority of green areas is designed to be on the eastern and northern edges of the sector <br/>
                underground power line of high voltage and trafostation are planned in the northern edge <br/>

                  <strong>in the north: </strong>sector S4 
                  <br />
                  <strong>in the east: </strong>Nová Polhora municipality (separated by insulating green area)
 <br />
                  <strong>in the south: </strong>service road

                  <br />
                  <strong>in the west: </strong>WWTP

                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s5} mb={3} />
                <h4 className="text-left strong mb-3">
                  Building complex S5 (27 702 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Description:</strong> consists of 2 buildings
                </p>
                <p>
                  <strong>Construction of the buildings:</strong> multi-nave and multi-modal
                </p>
                <p>
                  <strong>Material used:</strong> steel and reinforced concrete 
                </p>
              </Col>
              <Col md={12} className="pt-4">
                <p>
                <strong>12430 m2 of the site is sold to Nerezové materiály s.r.o. for the construction of warehouses.</strong>

                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S6+S7">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Sector S6 + S7</h2>
                <p className="mb-2">
                  <strong>Description:</strong> logistics area, includes the STK hall and administrative operation and the necessary range of traffic areas and green areas. <br/> 
                  This area (119 944 m2) will be divided into two benches of land (S6 approx. 66 426 m2, and S7 approx. 50 672 m2 ) intended for purchase and self-development
                </p>
                <p className="mb-2">
                  <strong>Entrance:</strong> main access to the premises from the special purpose road
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> 1 Sold | 1 Reserved | 3 Development opportunity
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infrastructure::
                </h4>
                <ul>
                  <li>the roads are designed for truck traffic</li>
                  <li>footpaths lead from the administration centre and warehouses to the future bus stop</li>
                  <li>parking for cars on the northern edge, at the higher service road</li>
                </ul>

                <h4 className="text-left strong mb-3">Surroundings:</h4>
                <p>
                a significant part of the greenery is proposed on the eastern and western edges of the site<br/>
                  <strong>in the north: </strong>service communication  
                  <br />
                  <strong>in the east: </strong>service communication <br />
                  <strong>in the south: </strong>Sector S7
                  <br />
                  <strong>in the west: </strong> collector communication

                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s7} mb={3} />
              </Col>
              <Col md={12} className="pt-4">
                <p>
                On the first plot is already implemented and approved building STK - technical inspection for cars and trucks. 
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <Container className="col-utilization" id="S7">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Sector S7</h2>
                <p className="mb-2">
                  <strong>Description:</strong> Logistics and production complex comprising warehouses, production halls, administrative offices and the required transport and green areas

                </p>
                <p className="mb-2">
                  <strong>Entrance:</strong>  main entrance from the collector road

                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> S7A:  Development opportunity | S7B:  Development opportunity
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infrastructure:
                </h4>
                <ul>
                  <li>roads are to form a circuit</li>
                  <li>roads are dimensioned for trucks</li>
                  <li>pavement is to lead from the docks to the future bus stop </li>
                  <li>parking lot for passenger cars– on the western edge, next to collector road </li>
                  <li>access to the warehouses and production from the north and the south</li>
                </ul>

                <h4 className="text-left strong mb-3">Surroundings:</h4>
                <p>
                majority of green areas is designed to be on the eastern and western edges of the sector<br/>
                  <strong>in the north: </strong> sector S6
                  <br />
                  <strong>in the east: </strong>road 3325 
 <br />
                  <strong>in the south: </strong>collector road
                  <br />
                  <strong>in the west: </strong>collector road
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s7} mb={3} />
                <h4 className="text-left strong mb-3">
                  Building complex S7 (87 981m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Description:</strong> building complex consists of 2 buildings 
                </p>
                <p>
                  <strong>Construction of the buildings:</strong> multi-nave and multi-modal
                </p>
                <p>
                  <strong>Material used:</strong> steel and reinforced concrete 
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>*/}

    </div>
    <SEO title="Architektúra" />
  </Layout>
));

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
